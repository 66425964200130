import {  Link } from 'react-router-dom'
import '../CSS/forget.css'
import forget from '../IMG/forget.png'
import { useState } from 'react'
import { useAuthStore } from '../../store/authStore'
import {Mail} from 'lucide-react'
function Forget(){
  const [email, setEmail]= useState("");
  const [isSubmitted, setIsSubmitted] = useState("")
  const {forgotPassword, error} = useAuthStore();
  const handleSubmit = async (e)=>{
    e.preventDefault();
    try {
      await forgotPassword(email);
    setIsSubmitted(true);
    } catch (error) {
      console.log(error)
    }
    
  }
    return(
        <div className="App">
        <div className="forget">
        {!isSubmitted ? (
         <form onSubmit={handleSubmit}>
         <img className='ft-forget' src={forget}/>
          <h1 className='redefinir'>Esqueceu sua senha ?</h1>
          <p>Digite seu e-mail para redefinir sua senha</p>
          <Mail
          className='icon'
          />
          <input
          type='email'
          value={email}
          placeholder='E-mail'
          onChange={(e)=> setEmail(e.target.value)}
          required
          ></input><br/>
        <button>Resetar Senha</button><br/>
       
        </form>
        ):(
          <div>
             <h1 className='redefinir'>Esqueceu sua senha ?</h1>
             <div className='circ'>
            <Mail className='icon-env'/>
            </div>
          <p className='text-forg'>Se existir uma conta para {email}, você receberá um link para redefinir sua senha em breve.</p>

          </div>
        )};
        <Link to='/login' className='back'>Voltar para o Login</Link>
        {error && <p className ='error-forgot'>{error}</p>}
        </div>
      </div>
    )
}

export default Forget
