import { Link, useNavigate, useParams } from 'react-router-dom'
import '../CSS/forget.css'
import forget from '../IMG/forget.png'
import { useState } from 'react'
import { useAuthStore } from '../../store/authStore'
import {Lock} from 'lucide-react'
import 'react-hot-toast'
import toast from 'react-hot-toast'
function Reset(){
  const [password, setPassword]= useState("");
  const [confirmPassword, setConfirmPassword] = useState("")
  const {resetPassword, error ,message} = useAuthStore();
  const {token} = useParams();
  const navigate = useNavigate();
  const handleSubmit = async (e)=>{
    e.preventDefault();
    if(password !== confirmPassword){
        alert("As Senhas precisam ser iguais")
        return;
    }
    try {
      await resetPassword(token, password)
        toast.success("Senha redefinida com Sucesso, redirecionando para o Login");
        setTimeout(() => {
            navigate("/login")
        }, 2000);
    } catch (error) {
      console.log(error)
      toast.error(error.message || "Erro ao Alterar a Senha")
    }
    
  }
    return(
        <div className="App">
        <div className="forget">
         <form onSubmit={handleSubmit}>
          <img className='ft-forget' src={forget}/>
          <h1 className='redefinir'>Reset de Senha</h1>
          <p>Digite sua nova Senha</p>
          <Lock
          className='icon'
          />
          <input
          type='password'
          value={password}
          placeholder='Digite sua Nova Senha'
          onChange={(e)=> setPassword(e.target.value)}
          required
          ></input><br/>
          <Lock
          className='icon'
          />
          <input
          type='password'
          value={confirmPassword}
          placeholder='Confirme sua Nova Senha'
          onChange={(e)=> setConfirmPassword(e.target.value)}
          required
          ></input>
        <button>Resetar Senha</button><br/>
       
        </form>
        <Link to='/login' className='back'>Voltar para o Login</Link>
        {error && <p className ='error-forgot'>{error}</p>}
        </div>
      </div>
    )
}

export default Reset
